.paymentcheck {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: var(--black);
}

.paymentsuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

.paymentsuccess .successmessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  text-align: center;
}

.paymentsuccess .successmessage .circle {
  background-color: var(--orange);
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.checkicon {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 32px;
  height: 42px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -65%);
  border: 2px solid transparent;
  border-radius: 100px
}
.checkicon::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 15px;
  height: 30px;
  border-width: 0 5px 5px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
  color: white;
}

.paymentsuccess .orderinformations {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3% 0;
}

.paymentsuccess .orderinformations img {
  width: 25px;
  cursor: pointer;
}

.paymentsuccess .orderinformations span {
  margin: 0 35px 0 5px;
  cursor: pointer;
  translate: 0 0;
  transition: all .4s ease;
}

.paymentsuccess .orderinformations span:hover {
  translate: 0 -3px;
}

.paymentsuccess .retour::after {
  content: "Retour a la page d'accueil";
  position: fixed;
  color: var(--orange);
  top: 20px;
  left: 20px;
  font-size: 1.3em;
  font-weight: 600;
  cursor: pointer;
  transition: all .5s ease;
  translate: 0 0;
}

.paymentsuccess .retour:hover::after {
  color: var(--black);
  translate: 10px 10px;
}

.order_confirmed_reciept {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
}

.order_confirmed_reciept img {
  width: 75px;
  align-self: flex-end;
}

.order_confirmed_reciept .element {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.order_confirmed_reciept span {
  display: block;
  min-width: 250px;
  padding: 2% 0;
  font-weight: 500;
}

.order_confirmed_reciept span:first-child {
  min-width: 150px;
  border-bottom: 1px solid rgba(238, 170, 43, 0.61);
}

.reciept_logo {
  display: none;
}


@media only screen and (max-width: 850px) {

  .retour::after {
    content: '< Back' !important;
    font-size: 1.5em !important;
    font-weight: 600 !important;
    
  }

  .paymentsuccess {
    background-color: antiquewhite;
    min-height: 100vh;
  }

  .paymentsuccess h3 {
    font-size: 1.4em !important;
  }

}

@media print {
 .retour {
  display: none;
 }

 .orderinformations {
  display: none !important;
 }

 .reciept_logo {
  display: block;
  width: 150px;
 }

 .paymentsuccess h3 {
  color: var(--orange);
  font-size: 2.2em;
 }

 .order_confirmed_reciept {
  margin-top: 3%;
 }
}


.checkmodalemail {
  position: fixed;  /* position the checkmodalemail in the center of the screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 90%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  z-index: 2;  /* make sure the checkmodalemail is above other elements */
}

/* Style the form and buttons within the checkmodalemail */
.checkmodalemail form {
  display: flex;
  flex-direction: column;
}

.checkmodalemail label {
  font-size: 14px;
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
}

.checkmodalemail input[type="email"] {
  font-size: 16px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
}

.checkmodalemail input[type="email"]:focus {
  border-color: #fca311;
  outline: none;
}

.checkmodalemail button[type="submit"] {
  font-size: 16px;
  background-color: #fca311;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  width: 30%;
}

.checkmodalemail button[type="submit"]:hover {
  background-color: #fdbe8e;
}

.checkmodalemail button[type="button"] {
  font-size: 16px;
  background-color: white;
  color: #444;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
}

.checkmodalemail button[type="button"]:hover {
  background-color: #f2f2f2;
}

/* Style the background to be slightly blurred */
.checkmodalemail-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);  /* transparent black */
  z-index: 1;  /* make sure the modal is above other elements */
}

.checkmodalemail button[type="submit"],
.checkmodalemail button[type="button"] {
  align-self: flex-end;  /* position the button at the end of the form */
}