.profile {
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: rgb(252, 250, 253);
}

.profile .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.profile .section {
  min-height: 75vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 7%;
}

.profile .section .avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile .section .avatar .useravatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 9em;
  aspect-ratio: 1/1;
  background-color: rgb(22, 20, 20);
  overflow: hidden;
  margin: 2%;
  position: relative;
}

.profile .section .avatar .useravatar.editmode::after {
  content: "selecte the new picture";
  color: white;
  position: absolute;
  height: 40%;
  width: 50%;
  text-align: center;
}

.profile .section .avatar .useravatar input {
  opacity: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.profile .avatar .useravatar img {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;
  background-color: white;
}

.profile .section .avatar span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4%;
  width: 30%;
  font-size: 1.3em;
}

.profile .section .avatar span * {
  max-height: 1.3em;
}

.profile .section .selection {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 4%;
  color: #393939;
  font-size: 1.3em;
  cursor: pointer;
}

.profile .section .selection span {
  display: block;
  transition: all 0.3s ease;
}

.profile .section .selection span::after {
  display: block;
  content: "";
  margin-top: 5px;
  height: 2px;
  width: 0%;
  background-color: black;
  transition: all 0.6s ease;
}

.profile .section .selection span.active {
  color: black;
}

.profile .section .selection span.active::after {
  width: 100%;
}

.profile .section .selection span:hover {
  color: black;
}

.profile .section .userinformations {
  width: 55%;
  display: flex;
  flex-direction: column;
}

.profile .section .userinformations button {
  margin-top: 5vh;
  width: 150px;
  align-self: flex-end;
  text-transform: uppercase;
  background-color: var(--orange);
  height: 45px;
  font-weight: 600;
  color: var(--black);
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 4vw;
}

.profile .section .userinformations .line {
  display: flex;
  flex-direction: row;
  margin: 1%;
  margin-left: 10%;
}

.profile .section .userinformations .line .userinfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #646464;
}

.profile .section .userinfobio {
  display: flex;
  flex-direction: column;
  margin: 1%;
  margin-left: 10%;
  color: #646464;
}

.profile .section .userinfobio textarea {
  text-transform: capitalize;
  font-size: 1em;
  padding: 10px;
  margin: 2px;
  background-color: #eeeaea;
  outline: none;
  border: none;
  width: 80%;
}

.profile .section .userinformations .line .userinfo input {
  text-transform: capitalize;
  font-size: 1.1em;
  padding: 10px;
  margin: 2px;
  background-color: #eeeaea;
  outline: none;
  border: none;
  width: 80%;
}

.profile .section .userinformations .line .userinfo select {
  text-transform: capitalize;
  font-size: 1.1em;
  padding: 10px;
  margin: 2px;
  background-color: #eeeaea;
  outline: none;
  border: none;
  width: 84.3%;
  min-width: 287px;
}

.profile .section .userinformations h3 {
  color: #353535;
  margin-top: 2em;
  margin-left: 2em;
}

.profile .section .userreservations {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile .editmode {
  border: 2px solid var(--orange) !important;
}

.profile .section .userreservations .observation {
  display: block;
  width: 60%;
  min-width: 500px;
  padding: 2%;
  background-color: #353535;
  color: white;
  line-height: 2em;
  margin-bottom: 3%;
}

.profile .section .userreservations .reservations {
  display: block;
  width: 60%;
  padding: 2%;
  border-bottom: 1px solid #353535;
  border-top: 1px solid #353535;
}

.profile .section .userreservations .reservations .reservation {
  margin: 4vh 0;
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.profile .section .userreservations .reservations .reservation .img,
.profile .section .userreservations .reservations .reservation > img {
  width: 40%;
  max-width: 300px;
  aspect-ratio: 2/1.3;
  background-color: #393939;
  flex: 2;
}

.profile .steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.profile .steps button {
  height: 30px;
  width: 120px;
  text-align: center;
  outline: none;
  font-size: 1em;
  border-radius: 0;
  background-color: #353535;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.profile .steps button:hover {
  background-color: rgb(29, 28, 28);
}

.profile .steps .step {
  background-color: #393939;
  color: white;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 10px 0 0 0;
  width: 35px;
  height: 30px;
  margin: 1em;
  transition: all 0.2s ease;
  position: relative;
}

.profile .steps .step.selected::after {
  position: absolute;
  content: "";
  top: 30px;
  width: 50%;
  left: 25%;
  height: 2px;
  background-color: white;
}

.profile .steps .step:hover,
.profile .steps .step:active {
  background-color: rgb(31, 30, 30);
}

.profile .section .userreservations .reservations .reservation .img img {
  width: 100%;
  height: 100%;
}

.profile .section .userreservations .reservations .reservationinformations {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2%;
  height: 80%;
}

.profile
  .section
  .userreservations
  .reservations
  .reservationinformations
  span {
  color: #464646;
  text-transform: capitalize;
  font-size: 1em;
}

.profile .section .userreservations .reservations .reservationinformations img {
  width: 40%;
  max-width: 150px;
  margin: 0.5em 0;
}

.profile
  .section
  .userreservations
  .reservations
  .reservationinformations
  span:first-child {
  font-size: 1.3em !important;
}

.profile
  .section
  .userreservations
  .reservations
  .reservationinformations
  span:last-child {
  color: #e9c546;
  font-size: 1.2em;
  margin-top: 3%;
}

.profile .section .userreservations .reservations .reservation .rebook {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #393939;
  gap: 4%;
}

.profile .section .userreservations .reservations .reservation .rebook button {
  padding: 3%;
  outline: none;
  height: 45px;
  border: 1px solid #353535;
  background-color: transparent;
  text-transform: capitalize;
  color: #353535;
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s all;
}

.profile
  .section
  .userreservations
  .reservations
  .reservation
  .rebook
  button:hover {
  background-color: #646464;
  color: white;
}

.profile .warning {
  cursor: pointer;
  display: block;
  color: rgb(124, 59, 59);
  font-size: 0.7em !important;
  position: fixed;
  top: 25%;
  right: 0%;
  transition: all 0.3s ease;
}

.profile .warning:hover {
  font-size: 1em !important;
}

.green {
  color: rgb(52, 184, 52) !important;
}

.profile .profilebuttons {
  display: flex;
  justify-content: flex-end !important;
}

.profile .profilebuttons button:first-child {
  background-color: var(--black) !important;
  color: white;
}

@media only screen and (max-width: 850px) {
  .profile .section {
    margin-top: 20vh;
  }
  .profile .section .avatar .useravatar > img {
    z-index: 1 !important;
  }

  .profile .selection {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    text-align: center;
    gap: 2vh;
  }

  .profile .selection > * {
    width: 250px !important;
    padding: 0.2em;
    background-color: var(--orange);
    color: var(--black);
  }

  .profile .userinformations {
    width: 90% !important;
  }

  .profile .userinformations .line {
    flex-direction: column !important;
  }

  .profile .observation {
    width: 30vw !important;
    min-width: 250px !important;
  }

  .profile .steps {
    width: 90% !important;
  }

  .profile .steps button {
    display: none;
  }

  .profile .reservations {
    width: 100% !important;
  }

  .profile .reservation {
    display: flex;
    flex-direction: column !important;
    border-bottom: 1px solid var(--black);
    padding-bottom: 20px;
  }

  .profile .reservation:last-child {
    border-bottom: none;
  }

  .profile .reservation .img {
    height: 200px !important;
    width: 100% !important;
  }

  .profile .reservationinformations {
    margin-bottom: 20px;
  }
}
