.footer {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 7.5%;
  background-color: #2d2c2c;
  transform: translateY(25%);
}

.footer h6 {
  flex: 3;
  font-family: "Montserrat";
  color: rgba(255, 255, 255, 0.5);
  font-size: 1em;
  font-weight: 400;
  line-height: 1em;
  margin: 2.5% 0;
}

.footer .social_media {
  color: var(--black);
}

.footer .social_media > a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  margin: 0;
  padding: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 2%;
}
