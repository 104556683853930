.checkservices {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkservices .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.checkservices .servicesmeetlucxury {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 85%;
  padding-top: 20vh;
  gap: 2%;
  margin-bottom: 5%;
  flex-wrap: wrap;
}

.checkservices .servicesmeetlucxury .left {
  flex: 0.7;
  position: relative;
  min-height: 500px;
  display: flex;
}

.checkservices .servicesmeetlucxury .left img {
  width: 70%;
  max-width: 500px;
  position: absolute;
  top: 0%;
  left: 9%;
  transition: all 0.6s ease;
}

.checkservices .servicesmeetlucxury .left img.img2 {
  left: 0;
  top: 8%;
}

.checkservices .servicesmeetlucxury .left .hoverstyle1 {
  top: 2.5%;
  left: 7.5%;
}

.checkservices .servicesmeetlucxury .left .hoverstyle2 {
  left: 2.5%;
  top: 6.5%;
}

.checkservices .servicesmeetlucxury .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.checkservices .servicesmeetlucxury .right h3 {
  font-size: 2.18em;
  line-height: 45px;
  text-align: left;
  margin-top: 9px;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-weight: 400;
}

.checkservices .servicesmeetlucxury .right p {
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.5em;
}

.checkservices .services {
  height: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 6%;
}

.checkservices .services2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%;
}

.checkservices .services2 i {
  margin: 1% 0 0;
  font-size: 1.7em;
  color: var(--orange);
}

.checkservices .services2 h3 {
  color: var(--black);
  font-family: "Montserrat Alternates";
  font-size: 3.13em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 1.8%;
  margin-bottom: 0.8%;
}

.checkservices .services2 .icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

.checkservices .services2 .icons .icon {
  margin: 5% 2%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.4s ease;
}

.checkservices .services2 .icons .icon:hover {
  transform: scale(1.03);
}

.checkservices .services2 .icons .ic {
  position: relative;
  margin-bottom: 0px;
  height: 150px;
  width: 350px;
}

.checkservices .services2 .icons .ic i {
  display: block;
  line-height: 120px;
  transition: 0.3s ease;
  font-size: 4.2em;
  text-align: center;
}

.checkservices .services2 .icons .ic:hover i::after {
  transform: scale(1.15);
}

.checkservices .services2 .icons .icon span {
  display: block;
  font-family: "Montserrat Alternates";
  width: 100%;
  text-align: center;
  color: #3a3534;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 36px;
}

.checkservices .services2 .icons .icon .fake_ic {
  position: absolute;
  height: 130%;
  width: 85%;
  left: 7.5%;
  transition: all 0.3s ease;
}

.checkservices .services2 .icons .icon .fake_ic:hover {
  transform: scale(1.2);
  box-shadow: 0 8px 21px 0 rgba(51, 51, 51, 0.14);
}

@media only screen and (max-width: 850px) {
  .servicesmeetlucxury {
    flex-direction: column !important;
    height: auto;
    margin: 0;
  }

  .servicesmeetlucxury .left {
    min-height: 350px !important;
    min-width: 450px;
    margin-bottom: 0;
  }

  .servicesmeetlucxury .left img {
    width: 300px !important;
  }

  .checkservices .servicesmeetlucxury .left img {
    left: 40px;
  }

  .checkservices .servicesmeetlucxury .left img.img2 {
    left: 0px;
    top: 8%;
  }

  .servicesmeetlucxury .right {
    width: 100%;
    margin-bottom: 0 !important;
    max-height: auto;
  }

  .checkservices .services {
    flex-wrap: wrap;
    justify-content: center;
  }
}
