.checkboxselection .title {
  display: inline-block;
  background-color: var(--black);
  padding-bottom: 13px !important;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Montserrat Alternates";
  color: var(--white);
  width: 85%;
  display: flex;
  align-items: center;
  padding: 4% 6%;
}

.checkboxselection .checkboxarea {
    margin: 8%;
    display: flex;
    flex-direction: row;
}

.checkboxselection .checkboxarea>input {
    margin-right: 5%;
}
