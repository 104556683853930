.header {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: row;
  width: 87%;
  align-items: center;
  justify-content: space-between;
  padding: 3% 3.65%;
  margin: 0.7%;
}

.header_logo {
  flex: 1;
}

.header_logo img {
  width: 6.25em;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.social_media {
  color: white;
  font-size: 15px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.social_media img {
  width: 17px;
}

.social_media > * {
  padding-bottom: 13px;
  margin: 5%;
}

.header_elements {
  flex: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header_elements ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  justify-content: center;
  padding-left: 8%;
}

.header_elements ul li {
  margin-right: 5%;
  cursor: pointer;
  line-height: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 13.5px;
  font-family: "Montserrat Alternates";
  color: white;
}

li a {
  padding-bottom: 13px;
}

.header_elements > ul li a::after {
  content: "";
  margin-top: 13px;
  width: 50%;
  height: 2px;
  display: block;
  background-color: white;
  transform: translateX(50%);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.header_elements ul li a:hover:after,
.header_elements ul li a.selected::after {
  opacity: 1;
}

.burgermenu {
  display: none;
}

.header .login_navbar_full {
  min-width: 20%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 8%;
  right: 10%;
}

.header .login_navbar_full ul {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 30px;
  background-color: #202020;
  border: 1px solid white;
  padding: 15px 20px;
  color: black;
}

.header .login_navbar_full ul li {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 5px;
  transition: 0.1s ease;
  color: black;
  filter: invert(100%) sepia(1%) saturate(7500%) hue-rotate(175deg)
    brightness(101%) contrast(99%);
}

.header .login_navbar_full ul li:hover {
  filter: invert(99%) sepia(43%) saturate(2412%) hue-rotate(306deg)
    brightness(95%) contrast(117%);
}

.header .login_navbar_full:hover ul,
.header .login_navbar_full ul:hover {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.header .login_navbar_info,
.header .mini_login_navbar_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2%;
  gap: 10px;
  color: white;
  text-transform: capitalize;
  transform: translateY(-17.5%);
  cursor: pointer;
  text-align: end;
}

.header .login_navbar_info .login_button,
.header
  .mini_login_navbar_info
  .login_button
  .header
  .login_navbar_info
  .register_button,
.header .mini_login_navbar_info .register_button {
  display: block;
  cursor: pointer;
  line-height: 10px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 0.95em;
  padding: 15px;
  border-radius: 3px;
  font-family: "Montserrat Alternates";
  text-transform: capitalize;
  color: var(--orange);
  transition: 0.3s ease;
}

.header .mini_login_navbar_info {
  display: none !important;
}

.header .login_navbar_info .login_button,
.header .mini_login_navbar_info .login_button {
  background-color: var(--orange);
  border: 1px solid var(--orange);
  color: var(--black);
}

.header .login_navbar_info .login_button:hover {
  background-color: transparent;
  color: var(--orange);
}

.header .login_navbar_info .register_button::after {
  display: block;
  content: "";
  height: 2px;
  width: 80%;
  transform: translateY(10px) translateX(13%);
  background-color: var(--orange);
  opacity: 0;
  transition: 0.3s ease-in;
}

.header .login_navbar_info .register_button:hover::after {
  opacity: 1;
}

.header .login_navbar_info img {
  border-radius: 20%;
  height: 40px;
  width: 40px;
}

.header .login_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: max(70vh, 500px);
  width: max(50vw, 900px);
  background-color: white;
  display: flex;
  flex-direction: row;
  color: #353535;
  z-index: 10;
  max-height: 600px;
}

.header .register_form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: max(90vh, 500px);
  width: max(50vw, 800px);
  background-color: white;
  display: flex;
  flex-direction: row;
  color: #353535;
  z-index: 10;
  max-height: 700px;
}

.header .login_blur_wrrapper {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
}

.header .login_form img,
.header .register_form img {
  flex: 5;
  width: 100%;
  background-color: #494949;
  height: 100%;
}

.header .login_form form,
.header .register_form form {
  padding: 3% 10%;
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header .login_form form h4,
.header .register_form h4 {
  font-size: 3em;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.header .login_form form p,
.header .register_form p {
  font-weight: 500;
  font-size: 1em;
  padding: 0;
  margin: 3% 0;
  width: 100%;
}

.header .login_form form .login_inputs {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-self: center;
}

.header .register_form form .register_inputs {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: flex-start;
  margin-top: 7%;
}

.header .login_form form .login_inputs input {
  min-height: 3.5em;
  padding: 1% 5%;
  min-width: 200px;
  margin-top: 6%;
  margin-bottom: 2%;
  outline: none;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  letter-spacing: 1px;
  color: #494949;
}

.header .register_form form .register_inputs input {
  min-height: 3em;
  padding: 1% 5%;
  min-width: 200px;
  margin-top: 2%;
  margin-bottom: 2%;
  outline: none;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  letter-spacing: 1px;
  color: #494949;
}

.header .login_form form .login_inputs input[type="password"] {
  -webkit-text-security: disc;
  text-security: disc;
  -webkit-text-security: square;
  text-security: square;
  -webkit-text-security: circle;
  text-security: circle;
  -webkit-text-security: none;
  text-security: none;
  color: #494949;
}

.header .login_form form .login_under_input {
  display: flex;
  flex-direction: row;
  width: 85%;
  align-self: center;
  font-size: 0.8em;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.header .login_form form .login_under_input > span:last-child {
  color: var(--orange);
  cursor: pointer;
}

.header .login_form form button,
.header .register_form form button {
  min-height: 2.8em;
  padding: 1% 5%;
  min-width: 200px;
  margin-top: 5%;
  margin-bottom: 1%;
  outline: none;
  background-color: #ffcc80;
  border: 1px solid #ffcc80;
  letter-spacing: 1px;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  width: 85%;
  align-self: center;
  border-radius: 3px;
  cursor: pointer;
}

.header .register_form form button {
  width: 90%;
  align-self: flex-start;
}

.header .login_form form > span,
.header .register_form form > span {
  width: 85%;
  text-align: end;
  align-self: center;
  font-size: 0.8em;
}

.header .register_form form > span {
  width: 80%;
}

.header .login_form form .close_login,
.header .register_form form .close_login {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 5%;
  cursor: pointer;
}

.header .login_form form > span b,
.header .register_form form > span b {
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  align-self: flex-end;
}

.header .login_form form > span b::after,
.header .register_form form > span b::after {
  content: "";
  display: block;
  height: 0.1em;
  width: 100%;
  align-self: flex-end;
  background-color: var(--orange);
}

.header .errormessageform {
  color: rgb(146, 65, 65);
  display: block;
  text-align: start !important;
  width: 90% !important;
  padding-right: 10%;
}

@media only screen and (max-width: 850px) {
  body > * {
    width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden;
  }
  .header {
    background-color: var(--black) !important;
    width: 100vw !important;
    height: 15vh !important;
    margin: 0 !important;
    position: fixed !important;
    z-index: 15 !important;
    padding: 0 !important;
  }

  .header_elements {
    position: fixed !important;
    left: -100% !important;
    height: calc(100vh + 100px) !important;
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--black) !important;
    width: 250px !important;
    border-right: 1px solid rgb(156, 140, 140) !important;
    transition: all 0.5s ease !important;
  }

  .header_elements ul {
    display: flex !important;
    flex-direction: column !important;
  }

  .header .social_media {
    display: none !important;
  }

  .header img {
    position: relative !important;
    top: 0 !important;
    margin-left: 10vw !important;
    transition: all 0.6s ease-in-out !important;
  }

  .header .burgermenu {
    display: flex !important;
    flex-direction: column !important;
    margin: auto 20% !important;
    cursor: pointer !important;
  }

  .header .burgermenu .line {
    height: 3px;
    width: 30px;
    border-radius: 2px;
    background-color: var(--orange);
    margin: 3px;
    transition: all 0.5s ease;
  }

  .header .burgermenu .line:last-child::after {
    display: block;
    content: "";
    height: 3px;
    width: 30px;
    border-radius: 2px;
    background-color: var(--orange);
    margin-top: 9px;
    transition: all 0.1s ease;
  }

  .header.active .burgermenu .line:first-child {
    transform: rotate(-47deg);
    translate: -0.5px 250%;
    height: 4px;

    border-radius: 4px;
  }

  .header.active .burgermenu .line:last-child {
    transform: rotate(47deg);
    height: 4px;
    border-radius: 4px;
  }

  .header.active .burgermenu .line:last-child::after {
    height: 0;
  }

  .header.active .header_elements {
    left: 0 !important;
    padding-top: 5vh !important;
  }

  .header.active .header_logo img {
    position: relative !important;
    top: 70px !important;
    z-index: 20 !important;
    width: 170px !important;
  }

  .header.active .header_elements ul li a::after {
    width: 13% !important;
    margin-bottom: 15px !important;
  }

  .header.active .social_media {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    position: fixed !important;
    bottom: 10px !important;
    left: 10px !important;
    width: 200px !important;
  }

  .header .login_form, .header .register_form {
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding: auto;
  }

  .header .login_form img, .header .register_form img {
    display: none;
  }

  .login_navbar_info,
  .login_navbar_info {
    display: none !important;
  }

  .header .mini_login_navbar_info {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 3vh;
    margin-top: 3vh;
  }

  .header .mini_login_navbar_info span {
    padding: 0 !important;
    text-align: center;
    background-color: transparent !important;
    border: none !important;
    color: var(--orange) !important;
  }

  .header .mini_login_navbar_info img {
    display: none;
  }

  .header .mini_login_navbar_info ul {
    align-items: flex-start;
  }

  .header .mini_login_navbar_full ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 5px;
    transition: 0.1s ease;
    color: white !important;
    filter: invert(99%) sepia(43%) saturate(2412%) hue-rotate(306deg)
      brightness(95%) contrast(117%);
  }

  .header .mini_login_navbar_full ul li span {
    color: black !important;
  }
}
