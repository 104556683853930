.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  translate: 0 -20%;
}

.loader .orange_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--orange);

animation: orange 1.2s ease-out infinite;
}

@keyframes orange {
  from {
    scale: 1;
    opacity: .1;
  }
  to {
    scale: 4;
    opacity: .8;
  }
}
