.item {
  display: flex;
  flex-direction: row;
  width: 61%;
  justify-content: space-between;
  margin-bottom: 6.5%;
}
.item i {
  font-weight: normal;
  margin-right: 8%;
}

.item .desc {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.item .desc h5 {
  margin: 0;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 1.4em;
  font-weight: 400;
  margin-bottom: 1.95%;
}

.item .desc span {
  font-family: "Montserrat Alternates";
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 2px;
  opacity: 0.6;
  white-space: pre-line;
}
