.feedback {
  width: 30.5%;
  min-width: 350px;
  min-height: 200px;
  max-height: 450px;
  margin-bottom: 2%;
}

.feedback .feedback_content {
  background-color: var(--white);
  position: relative;
  background: #fff;
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 35px;
  padding: 79px 40px 80px;
  box-shadow: 0px 30px 50px 0px rgba(153, 153, 153, 0.15);
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
}

.feedback .feedback_content::before {
  content: "\10fc5b \10fc5b \10fc5b \10fc5b \10fc5b" !important;
  font-family: "stmicons" !important;
  font-size: 25px;
  letter-spacing: 6px;
  position: absolute;
  top: 30px;
  left: 39px;
  color: var(--orange);
}

.feedback .feedback_content::after {
  content: "\10fff9";
  font-family: "stmicons" !important;
  font-size: 20px;
  position: absolute;
  left: auto;
  top: auto;
  right: 30px;
  bottom: 30px;
  color: var(--orange);
}

.feedback span {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
