.room_page {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.room_page .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.room_page h1 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 3.4em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin-top: 8%;
}

.room_page .info_icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.room_page .info_icons i {
  margin: 0 1% 0 2%;
  color: var(--orange);
  font-size: 1.1em;
}

.room_page .info {
  width: 85%;
  display: flex;
  flex-direction: row;
  margin-bottom: 9%;
}

.room_page .info .check {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.92;
  background-color: #3a3534;
  padding-bottom: 45px;
  position: relative;
  max-height: 500px;
}

.room_page .datepicker {
  top: 18%;
  left: 12%;
  box-shadow: 4px 7px 11px -3px rgba(223, 223, 223, 0.19);
  -webkit-box-shadow: 4px 7px 11px -3px rgba(223, 223, 223, 0.19);
  -moz-box-shadow: 4px 7px 11px -3px rgba(223, 223, 223, 0.19);
}

.room_page .info .check .room_input {
  background-color: var(--white);
  height: 45px;
  width: 75%;
  border: none;
  color: #3a3534;
  font-size: 1em;
  font-family: "Montserrat";
  white-space: nowrap;
  text-align: center;
  margin-top: 45px;
}

.room_page .info .check .basic_select {
  height: 45px;
  width: 75%;
  padding: 0;
  transform: translateX(-2px);
  margin-top: 25px;
  background-color: var(--white) !important;
}

.room_page .info .check .price {
  width: 65%;
  font-weight: 600;
  font-size: 12px;
  vertical-align: top;
  letter-spacing: 2px;
  word-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  padding: 5%;
  border: 1px solid #918a89;
  line-height: 38px;
  font-family: "Montserrat Alternates";
  margin-top: 25px;
}

.room_page .info .check .price > span {
  color: var(--orange);
  font-size: 2.5em;
  letter-spacing: 0;
  direction: rtl;
}

.room_page .info .details {
  flex: 2;
}

.room_page .info .available,
.room_page .info .notavailable {
  font-size: 1.4em;
  letter-spacing: 1px;
  margin-top: 40px;
  width: 75%;
  text-align: center;
}

.room_page .info .available {
  margin-top: 25px;
  color: rgba(15, 221, 15, 0.781);
}

.room_page .info .notavailable {
  color: var(--orange);
}

.room_page .info .error {
  margin-top: 25px;
  color: var(--orange);
}

.room_page .info .full_button {
  background-color: var(--orange);
  border-radius: 2%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  color: #3a3534;
  padding: 1% 2.5%;
  transition: all 0.5s ease;
  cursor: pointer;
  width: 70%;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 25px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}

.room_page .info .full_button:hover {
  background-color: #3a3534;
  color: var(--white);
  border: 1px solid var(--orange);
}

.room_page .details .one {
  margin-left: 4%;
  background-color: var(--grey);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.room_page .details .one .element {
  height: 55px;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2% 0% 2% 2%;
  background-color: var(--white);
  font-size: 12px;
  color: #333;
  text-align: center;
}

.room_page .details .two,
.room_page .details .three {
  margin-left: 4%;
  margin-bottom: 5%;
}

.room_page .details .two h3,
.room_page .details .three h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-transform: uppercase;
  margin-top: 0%;
}

.room_page .details .two .element {
  width: 100%;
  padding-bottom: 1.5%;
  border-bottom: 1.5px solid rgba(58, 53, 53, 0.166);
  margin-top: 2%;
  display: flex;
  color: rgba(58, 53, 53, 0.966);
  justify-content: space-between;
  font-size: 1.1em;
}

.room_page .details .three {
  display: flex;
  flex-direction: column;
}

.room_page .details .three .head {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  min-width: 350px;
  background-color: rgb(238, 238, 238);
  color: rgb(61, 59, 59);
  font-weight: 600;
  font-size: 1.1em;
  padding: 3% 2%;
}

.room_page .details .three .head > * {
  min-width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.room_page .room_loader {
  margin-top: 15%;
}

.room_page .details .three .element {
  padding: 3% 2%;
  width: 50%;
  min-width: 350px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  color: rgb(61, 59, 59);
  font-weight: 600;
  font-size: 1em;
  border-bottom: 1px solid rgb(143, 136, 136, 0.4);
}

.room_page .details .three .element > * {
  width: 20%;
}

@media only screen and (max-width: 850px) {
  .room_page h1 {
    margin-top: 20vh;
  }
  .room_page .info {
    flex-direction: column-reverse;
  }
  .room_page .info .details {
    margin-bottom: 40px;
  }
}
