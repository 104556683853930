.service {
  width: 85%;
  display: flex;
  flex-direction: row;
}

.service .image {
  flex: 1;
  overflow: hidden;
  height: 360px;
}

.service img {
  height: 100%;
  width: 100%;
  z-index: -1;
  transition: 0.8s cubic-bezier(0.06, 0.43, 0.22, 1);
}

.service img:hover {
  transform: scale(1.1);
}

.service .description {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--white);
  padding: 5% 0 3% 0;
}

.service .description h4 {
  padding: 0;
  margin: 0;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 2px;
}

.service .description p {
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  text-align: center;
  font-size: 0.94em;
  line-height: 25px;
  margin: 0 10%;
  font-weight: 400;
}

.service .arrow_button {
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  color: var(--orange);
  border: 1px solid var(--orange);
  transition: all 1s cubic-bezier(0.06, 0.43, 0.22, 1);
  cursor: pointer;
}

.service .arrow_button:hover {
  color: var(--white);
  background-color: var(--orange);
}

.service_services {
  width: 25%;
  min-width: 250px;
  height: 80vh;
  position: relative;
  overflow: hidden;
  background-color: rgb(44, 40, 40);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
  justify-content: center;
}

.service_services:hover {
  transform: scaleY(1.1);
}

.service_services:hover img {
  opacity: 0.25;
  transform: scaleY(0.91);
}

.service_services > * {
  z-index: 1;
}

.service_services img {
  height: 90vh;
  min-height: 650px;
  opacity: 0.5;
  position: absolute;
  z-index: 0;
  transition: all 2s cubic-bezier(0.06, 0.43, 0.22, 1);
  transform: translateX(12%);
}

.service_services i {
  font-size: 5.5em;
  color: var(--orange);
}

.service_services .default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: absolute;
  top: 27%;
}

.service_services .default h2 {
  font-family: "Montserrat Alternates";
  color: var(--white);
  font-size: 1.7em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.service_services .hover {
  opacity: 0;
  font-family: "Montserrat Alternates";
}

.service_services .hover h2 {
  color: var(--white);
  font-size: 3.5em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 30px;
}

.service_services .hover p {
  text-align: center;
  color: var(--white);
  font-size: 1.2em;
  padding: 0 7%;
}

.service_services:hover .hover {
  position: absolute;
  top: 20%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

.service_services:hover .default {
  opacity: 0;
}

@media only screen and (max-width: 850px) {
  .service {
    flex-direction: column !important;
  }
  .service_services {
    height: 400px;
    width: 50%;
    min-width: 300px;
  }

  .service_services img {
    scale: 1.4 1;
  }
}

@media only screen and (max-width: 600px) {
  .service_services {
    width: 100%;
  }

  .service_services img {
    scale: 1.8 1;
  }
}
