/* .gallerie {
  overflow: hidden;
} */

.gallerieslider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

.gallerieslider .loader {
  height: 45vh;
}

.gallerieslider .active_img {
  position: relative;
  width: 85%;
  height: 550px;
  min-width: 550px;
}

.gallerieslider .active_img img,
.gallerieslider .active_img .img {
  width: 100% !important;
  height: 100%;
}

.gallerieslider .active_img .angle_right,
.gallerieslider .active_img .angle_left {
  position: absolute;
  z-index: 1;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--black);
  border-radius: 3px;
  cursor: pointer;
  top: 43.8%;
  opacity: 0;
  transition: all 0.3s ease;
}

.gallerieslider:hover .angle_right,
.gallerieslider:hover .angle_left {
  opacity: 1 !important;
}

.gallerieslider .angle_left {
  left: 4%;
}

.gallerieslider .angle_right {
  right: 4%;
}

.gallerieslider .angle_left:hover,
.gallerieslider .angle_right:hover {
  background-color: var(--orange);
}

/* .gallerieslider .imgs {
  display: flex;
  flex-direction: row;
  gap: 0.84%;
  margin: 1%;
  position: relative;
} 

 .gallerieslider .imgs > img {
  width: 85vw !important;
  height: 97vh;
  transform: translateX(-3%);
}

.gallerieslider .ar {
  font-size: 27px !important;
  color: var(--white);
  cursor: pointer;
  font-weight: 400;
}

.gallerieslider .slide {
  position: relative;
  transform: translateX(-3%);
  transition: all 1s ease;
}

.gallerieslider .slide > img {
  width: 85vw !important;
  height: 97vh;
}

.gallerieslider .active_img .angle_right,
.gallerieslider .active_img .angle_left {
  position: absolute;
  z-index: 1;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  border-radius: 3px;
  cursor: pointer;
  top: 43.8%;
  opacity: 0;
  transition: all 0.3s ease;
}

.gallerieslider:hover .angle_right,
.gallerieslider:hover .angle_left {
  opacity: 1 !important;
}

.gallerieslider .angle_left {
  left: 4%;
}

.gallerieslider .angle_right {
  right: 4%;
}

.gallerieslider .angle_left:hover,
.gallerieslider .angle_right:hover {
  background-color: var(--orange);
} */

.gallerieslider h5 {
  font-family: "Montserrat Alternates" !important;
  line-height: 14px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 20px;
  font-weight: 400;
  margin: 0.3%;
  margin-bottom: 1.2%;
  position: relative;
  top: -5vh;
}

@media only screen and (max-width: 850px) {
  .gallerieslider .active_img {
    min-width: 350px;
    height: auto;
    aspect-ratio: 1/0.75;
  }

  .gallerieslider .active_img .angle_right,
  .gallerieslider .active_img .angle_left {
    height: 45px;
    width: 45px;
  }
}
