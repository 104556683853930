.contact {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.contact h3 {
  align-self: flex-start !important;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  width: 50%;
  margin: 5% 10%;
  margin-bottom: 1%;
}

.contact p {
  align-self: flex-start;
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-size: 20px;
  font-weight: 400;
  width: 60%;
  margin: 0% 10%;
  margin-bottom: 5%;
}

.contact .info {
  height: 120vh;
  min-height: 720px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f3eee5;
}

.contact .info .left {
  width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact .info .left h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 3em;
  line-height: 45px;
  font-weight: 400;
  width: 61%;
  margin-bottom: 9%;
}

.contact .info .item {
  margin-top: 10%;
  width: 61%;
}

.contact .info .item span {
  font-size: 1em;
  opacity: 0.8;
}

.contact .info .right {
  width: 66%;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 7%;
}

.contact form i {
  margin-top: 7%;
  font-size: 1.7em;
  color: var(--orange);
}

.contact form h3 {
  margin: 0;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 50px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  text-align: center;
  width: 100%;
  margin: 2% 0;
}

.contact form .inpts {
  width: 55%;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.contact form .inpts input {
  font-family: "Montserrat";
  width: 41%;
  height: 39px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
  margin-bottom: 4%;
  font-size: 1.3em;
  min-width: 250px;
  border-radius: 0;
  padding: 1% 1% 1% 3%;
  color: var(--black);
  transition: all .4s ease;
}

.contact form .inpts textarea{
  font-family: "Montserrat";
  width: 91%;
  min-width: 250px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
  font-size: 1.3em;
  min-width: 250px;
  border-radius: 0;
  color: var(--black);
  padding: 1% 1% 1% 3%;
  transition: all .4s ease;
  margin-bottom: 80px;
}

.contact form input:focus,.contact form textarea:focus {
  border: 2px solid var(--orange);
}

.contact form input::placeholder,.contact form textarea::placeholder {
  opacity: .3;
}

.contact form input::-moz-placeholder,.contact form textarea::-moz-placeholder {
  opacity: .3;
}

.contact form .full_button {
  background-color: var(--orange);
  border-radius: 2%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  color: var(--white);
  padding: 1% 2.5%;
  letter-spacing: 1px;
  transition: all .5s ease;
  cursor: pointer;
}

.contact form .full_button:hover {
  background-color: var(--black);
}

@media only screen and (max-width: 850px) {
  .contact h3 {
    margin-top: 150px !important;
  }

  .contact .info {
    flex-direction: column;
    height: auto;
    padding: 0;
    display: block;
    min-height: 450px;
  }

  .contact .info .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 50px;
  }

  .contact .info .left h3 {
    margin-top: 40px !important;
  }

  .contact .info .right {
    height: auto;
    width: 100%;
    height: 350px;
  }

  .contact .info .item {
    margin: 0;
    display: flex;
    width: 100%;
  }

  .contact form h3 {
    margin-top: 30px !important;
  }

  .contact form textarea {
    width: 41% !important;
    margin-bottom: 150px;
  }
  
}