.paymenterror {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.paymenterror .errormessage {
  display: flex;
  flex-direction: column;
  gap: 5%;
  color: var(--orange);
  font-family: "Montserrat";
  font-size: 1.5em;
  align-items: center;
}

.paymenterror .errormessage img {
  width: 64px;
  align-self: center;
}

.paymenterror .errormessage button {
  width: 250px;
  margin-top: 50px;
  background-color: var(--black);
  border: 1px solid var(--orange);
  border-radius: 4px;
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px 10px;
  color: white;
  font-weight: 700px;
  align-self: center;
  cursor: pointer;
  transition: all 0.4s ease;
}

.paymenterror .errormessage span {
  color: rgb(211, 63, 63);
  font-size: 20px;
  width: 80%;
  text-align: center;
}

.paymenterror .errormessage button:hover {
  background-color: var(--orange);
  color: var(--black);
}

.satim_call_us {
  display: flex;
  flex-direction: column;
  justify-content: center;
}