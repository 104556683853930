.checkoutratehome,
.checkoutratechambres {
  width: 79%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20vh;
  position: relative;
  top: -9vh;
  justify-content: space-between;
  padding: 0.5% 3%;
  gap: 3%;
  box-shadow: 1px 30px 31px -8px rgba(199, 185, 185, 0.33);
  -webkit-box-shadow: 1px 30px 31px -8px rgba(199, 185, 185, 0.33);
  -moz-box-shadow: 1px 30px 31px -8px rgba(199, 185, 185, 0.33);
}

.checkoutratehome > button,
.checkoutratechambres > button {
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: 5px;
  height: 40%;
  color: var(--white);
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 0.9em;
  padding: 1% 3.5%;
  font-family: "Montserrat";
  line-height: 5px;
  white-space: nowrap;
  flex: 1.1;
  cursor: pointer;
}

.checkoutratehome .basic_select {
  height: 37%;
  flex: 1.5;
}

.checkoutratehome > input,
.checkoutratechambres > input {
  flex: 2.3;
  height: 37%;
  color: var(--white);
  font-size: 1em;
  font-family: "Montserrat";
  white-space: nowrap;
  border: none;
  background-color: var(--grey);
  color: var(--black);
  padding: 0 0 0 2%;
  border: 2px solid transparent;
}

.checkoutratehome > input:focus {
  outline: 0 none;
  border: 2px solid var(--orange);
}

.checkoutratechambres {
  background-color: var(--grey);
  height: 16vh;
  box-shadow: none;
  padding: 0 3%;
  margin-bottom: 7%;
  max-width: 1200px;
}

.checkoutratechambres > .checkchambre_input {
  background-color: var(--white);
  flex: 2.2;
  height: 41%;
  background-color: var(--white);
}

.checkoutratechambres > .checkchambre_button {
  background-color: var(--orange);
  border-color: var(--orange);
  padding-left: 3%;
  height: 45%;
  flex: 1;
  transition: all .4s ease;
  max-width: 40%;
}

.checkoutratechambres > .checkchambre_button:hover {
  background-color: var(--black);
  border-color: var(--black);
}

.checkoutratechambres > .checkchambre_input:focus {
  outline: none;
}

.checkoutratechambres .checkchambre_span {
  font-family: "Montserrat Alternates";
  display: inline-block;
  flex: 1;
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  margin-bottom: 0;
  margin-left: 30px;
  font-weight: normal;
  color: rgba(34, 34, 34, 0.7);
}



@media only screen and (max-width: 850px) {
  .checkoutratechambres, .checkoutratehome {
    flex-direction: column;
    min-height: 250px;
    padding: 20px;
  }

  .checkoutratechambres {
    min-height: 150px !important;
    padding: 3% 4%;
  }

  .checkoutratechambres input , .checkoutratehome input {
    width: calc(100% - 15px);
    min-height: 40px;
    max-height: 50px;
    padding: 0 5px 0 10px;
    margin: 0;
  }

  .checkoutratechambres > .checkchambre_button {
    max-height: 45px !important;
    max-width: 250px !important;
  }
}

/* =============================================================
 * BASE STYLES
 * ============================================================*/
.datepicker {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  width: 260px;
  height: 450px;
  z-index: 1;
  font-family: "Montserrat" !important;
  padding: 0.3%;
}

.checkoutratehome .datepicker {
  top: 70%;
}

.checkoutratechambres .datepicker {
  top: 38.5%;
}

#datepicker-2 {
  display: none;
}

.datepicker--inline {
  position: relative;
}

.datepicker__inner {
  overflow: hidden;
}

.datepicker__month {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}

.datepicker__month--month2 {
  display: none;
}

.datepicker__month-day--valid {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker__month-day--lastMonth,
.datepicker__month-day--nextMonth {
  visibility: hidden;
}

.datepicker__month-button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker__info--feedback {
  display: none;
}

.datepicker__info--error,
.datepicker__info--help {
  display: block;
}

datepicker__buttons {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker__close-button,
.datepicker__clear-button {
  cursor: pointer;
}

.datepicker__tooltip {
  position: absolute;
}

.checkoutratechambres {
  width: 92%;
  position: static;
}

/* =============================================================
   * THEME
   * ============================================================*/
.datepicker {
  background-color: #fff;
  color: #484c55;
  font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
  font-size: 14px;
  box-shadow: 23px -1px 43px -23px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 23px -1px 43px -23px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 23px -1px 43px -23px rgba(0, 0, 0, 0.09);
}

.datepicker__inner {
  padding: 20px;
}

.datepicker__month {
  font-size: 1em;
  color: #bdbdbd;
  letter-spacing: 2px;
}

.datepicker__month-caption {
  height: 2.5em;
  vertical-align: middle;
  padding-bottom: 20px !important;
}

.datepicker__month-name {
  text-transform: uppercase;
  font-weight: 600;
}

.datepicker__week-days {
  height: 3em;
  vertical-align: middle;
  color: var(--black);
  font-size: 1em;
  font-weight: bold !important;
}

.datepicker__week-name {
  font-size: 11px;
  font-weight: 600;
  color: rgba(34, 34, 34, 0.7);
  text-transform: uppercase;
  padding: 3px;
  letter-spacing: 2px;
}

.datepicker__month-day {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--black);
  padding: 9px 7px;
  border-bottom: 1px solid #eee;
  font-size: 0.8em;
  font-family: inherit;
}

.datepicker__month-day--no-checkin {
  position: relative;
}

.datepicker__month-day--no-checkin:before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-bottom: 8px solid red;
  border-right: 8px solid transparent;
}

.datepicker__month-day--no-checkout {
  position: relative;
}

.datepicker__month-day--no-checkout:before {
  top: 0;
  content: "";
  display: block;
  right: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 8px solid red;
  border-left: 8px solid transparent;
}

.datepicker__month-day--no-checkin.datepicker__month-day--no-checkout:before {
  content: none;
}

.datepicker__month-day--invalid {
  color: #e8ebf4;
}

.datepicker__month-day--disabled {
  color: #e8ebf4;
  position: relative;
}

.datepicker__month-day--disabled:after {
  content: "\00d7";
  left: 50%;
  position: absolute;
  color: red;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.datepicker__month-day--no-checkin,
.datepicker__month-day--no-checkout,
.datepicker__month-day--day-of-week-disabled {
  background-color: rgba(232, 235, 244, 0.5);
}

.datepicker__month-day--selected {
  background-color: #f5f4f3;
  color: var(--black);
}

.datepicker__month-day--selected:after,
.datepicker__month-day--selected:before {
  display: none;
}

.datepicker__month-day--hovering {
  background-color: #f5f4f3;
  color: var(--black);
}

.datepicker__month-day--today {
  background-color: #484c55;
  color: #fff;
}

.datepicker__month-day--first-day-selected,
.datepicker__month-day--last-day-selected {
  background-color: #609beb;
  color: var(--black);
}

.datepicker__month-day--last-day-selected:after {
  content: none;
}

.datepicker__month-button {
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-size: 1.3em;
  font-weight: 800;
  line-height: 30px;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--black);
  display: inline-block;
  padding: 5px 10px;
  font-size: 1.3em;
}

.datepicker__month-button:hover {
}

.datepicker__info {
  font-family: "Montserrat";
}

.datepicker__topbar {
  margin-bottom: 20px;
  position: relative;
}

.datepicker--topbar-bottom .datepicker__topbar {
  margin-bottom: 0;
  padding-top: 20px;
  border-top: 1px solid #dcdcdc;
}

.datepicker__info-text {
  font-size: 13px;
}

.datepicker__info--selected {
  font-size: 11px;
}

.datepicker__info--selected-label {
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.datepicker__info-text--selected-days {
  font-size: 11px;
  font-style: italic;
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.datepicker__info--error {
  color: red;
  font-size: 13px;
  font-style: italic;
}

.datepicker__info--help {
  color: #acb2c1;
  font-style: italic;
}

.datepicker__close-button,
.datepicker__clear-button,
.datepicker__submit-button {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--orange);
  border-radius: 25px;
  padding: 0 20px;
  height: 50px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
  text-decoration: none;
  text-shadow: none;
  font-family: "Montserrat";
}

.datepicker__close-button:hover,
.datepicker__clear-button:hover,
.datepicker__submit-button:hover {
  background-color: var(--black);
  color: var(--white);
}

.datepicker__close-button:disabled,
.datepicker__close-button[disabled],
.datepicker__clear-button:disabled,
.datepicker__clear-button[disabled],
.datepicker__submit-button:disabled,
.datepicker__submit-button[disabled] {
  background-color: #d6dae5;
  color: #9da6b8;
  cursor: not-allowed;
}

.datepicker__clear-button + .datepicker__submit-button {
  margin-left: 10px;
}

.datepicker__submit-button {
  width: auto;
}

.datepicker__tooltip {
  background-color: #ffe684;
  border-radius: 2px;
  font-size: 11px;
  margin-top: -5px;
  padding: 5px 10px;
}

.datepicker__tooltip:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #ffe684;
  bottom: -4px;
  content: "";
  left: 50%;
  margin-left: -4px;
  position: absolute;
}

@media (min-width: 320px) {
  .datepicker {
    width: 300px;
  }
}

@media (min-width: 480px) {
  .datepicker {
    width: 460px;
  }
  .datepicker__months {
    overflow: hidden;
  }
  .datepicker__month {
    width: 200px;
  }
  .datepicker__month--month1 {
    float: left;
  }
  .datepicker__month--month2 {
    display: table;
    float: right;
  }
  .datepicker__month-button--disabled {
    visibility: hidden;
  }
  .datepicker__months {
    position: relative;
    height: 320px;
  }
  .datepicker__months:before {
    background: #dcdcdc;
    bottom: 0;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

@media (min-width: 768px) {
  .datepicker {
    width: 560px;
  }
  .datepicker__month {
    width: 240px;
  }
  .datepicker--topbar-has-close-button .datepicker__info,
  .datepicker--topbar-has-clear-button .datepicker__info,
  .datepicker--topbar-has-submit-button .datepicker__info {
    max-width: 350px;
    margin-bottom: 12%;
  }
  .datepicker__close-button {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .datepicker__buttons {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .datepicker__months {
    width: 520px;
  }
}