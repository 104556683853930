.notfounderror {
    display: flex;
    flex-direction: column;
    height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  padding: 0 6%;
  font-weight: 400;
  color: white;
}


.notfounderror > div:first-child {
    font-size: 13.5em;
}

.notfounderror > div:nth-child(2) {
    font-size: 1.7em;
    max-width: 450px;
    font-family: 'Montserrat Alternates';
}

.notfounderror > button {
    margin-top: 4%;
    max-width: 250px;
    background-color: var(--orange);
    outline: none;
    border: none;
    cursor: pointer;
    padding: 1.15%;
    font-size: 1.2em;
    color: white;
    border-radius: 4px;
    transition: background-color .3s ease;
}

.notfounderror > button:hover {
    background-color: var(--black);
}