.galleriep {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 66vh;
  align-items: center;
}

.galleriep .mini_navg {
  height: 25vh;
  max-height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
}

.galleriep .mini_navg span {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-width: 250px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 1em;
  text-align: center;
  color: #333333;
  cursor: pointer;
}

.galleriep .mini_navg span:hover {
  color: var(--orange);
}

.galleriep .mini_navg span.active::after {
  opacity: 1;
}

.galleriep .mini_navg span::after {
  opacity: 0;
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--orange);
  position: relative;
  margin-top: 0.65em;
  transition: all 0.5s ease;
}

.galleriep .images_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 85%;
  margin-bottom: 10px;
  height: 100%;
  column-gap: 2%;
}

.galleriep .images_container > * {
  margin: 10px 0px;
  cursor: pointer;
}

.galleriep .images_container .picture {
  width: 32%;
  aspect-ratio: 1 / 0.7;
  background-color: #333333;
  transition: all 0.35s ease;
  min-width: 150px;
}

.galleriep .images_container .picture:hover {
  scale: 1.05;
}

.galleriep button {
  color: var(--orange);
  border: 1px solid var(--orange);
  background-color: transparent;
  border-radius: 6px;
  padding: 1%;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 2%;
}

.galleriep button:hover {
  background-color: var(--orange);
  color: white;
}

.hid {
  opacity: 0;
  max-height: 0;
}

@media only screen and (max-width: 850px) {
  .galleriep {
    min-height: 100vh;
  }
  .galleriep .mini_navg {
    margin-top: 20vh;
    flex-direction: column;
  }
  .galleriep .images_container {
    flex-direction: column;
    align-items: center;
  }
  .galleriep .images_container .picture {
    min-width: 300px
  }
}
