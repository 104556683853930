.conference {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conference .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.conference .content {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
  gap: 2.7%;
  margin-bottom: 7%;
  min-height: 45vh;
}

.conference .content h2 {
  color: #3a3534;
  font-size: 3.2em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 2%;
  margin-bottom: 0;
}

.conference .content p {
  color: #3a3534;
  margin-top: 0;
  margin-bottom: 3%;
}

.conference .conferences {
  width: 92%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.conference .conferences > * {
    margin: 2%;
}

.conference .room {
  box-shadow: 0 30px 50px rgba(153, 153, 153, 0.15) !important;
  -webkit-box-shadow: 0 30px 50px rgba(153, 153, 153, 0.15) !important;
}

.conference .room .description .price {
  top: -40px !important;
}

.conference .room .description h4 {
  top: -20px;
}

@media only screen and (max-width:850px){
  .conference .content {
    margin-top: 20vh;
  }
}