@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@font-face {
  font-family: "stmicons";
  src: url(/src/Assets/Fonts/stm.ttf);
}

:root {
  --white: #fff;
  --black: rgb(58, 49, 56);
  --grey: #f5f5f5;
  --orange: #ffac41;
}

body {
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("/src/Assets/Images/Background.png");
  background-repeat: repeat; */
  background-color: white;
  overflow-x: hidden;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 850px) {
  h3 {
    font-size: 2em !important;
  }
}

@font-face {
  font-family: "stmicons";
  src: url("/src/Assets/Fonts/stm.eot?htp3bx");
  src: url("/src/Assets/Fonts/stm.eot?htp3bx#iefix") format("embedded-opentype"),
    url("/src/Assets/Fonts/stm.ttf?htp3bx") format("truetype"),
    url("/src/Assets/Fonts/stm.woff?htp3bx") format("woff"),
    url("/src/Assets/Fonts/stm.svg?htp3bx#stmicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="stmicon-"],
[class*=" stmicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "stmicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea:focus,
input:focus {
  outline: none;
}
.stmicon-haircut:before {
  content: "\e901";
}
.stmicon-arrow_forward:before {
  content: "\10ff58";
}
.stmicon-guest:before {
  content: "\e904";
}
.stmicon-nipple:before {
  content: "\e903";
}
.stmicon-bed2:before {
  content: "\e930";
}
.stmicon-ruler:before {
  content: "\e906";
}
.stmicon-hotel-internet:before {
  content: "\10ff92";
}
.stmicon-hotel-breakfast:before {
  content: "\10fff0";
}
.stmicon-hotel-concierge:before {
  content: "\10fff1";
}
.stmicon-hotel-drinks:before {
  content: "\10fff2";
}
.stmicon-hotel-event:before {
  content: "\10fff3";
}
.stmicon-hotel-fitness:before {
  content: "\10fff4";
}
.stmicon-hotel-laundry:before {
  content: "\10fff5";
}
.stmicon-hotel-map-pin:before {
  content: "\10fff6";
}
.stmicon-hotel-meetings:before {
  content: "\10fff7";
}
.stmicon-hotel-pool:before {
  content: "\10fff8";
}
.stmicon-hotel-restaurant:before {
  content: "\10fffa";
}
.stmicon-hotel_parking-1:before {
  content: "\10ffd6";
}
.stmicon-rate_review:before {
  content: "\10fcf3";
}
.stmicon-star:before {
  content: "\10fc5b";
}
.stmicon-photo1:before {
  content: "\e909";
}
.stmicon-museum:before {
  content: "\e90a";
}
.stmicon-shopping:before {
  content: "\e908";
}
.stmicon-cultural:before {
  content: "\e907";
}
.stmicon-hotel-fitness:before {
  content: "\10fff4";
}
.stmicon-hotel-meetings:before {
  content: "\10fff7";
}
.stmicon-bell:before {
  content: "\e92c";
}
.stmicon-restaurant_menu:before {
  content: "\10fbcf";
}
.stmicon-car:before {
  content: "\e913";
}
.stmicon-smoke_free:before {
  content: "\10fc6e";
}
.stmicon-wifi-1:before {
  content: "\e924";
}
.stmicon-television:before {
  content: "\e915";
}
.stmicon-safe_secure:before {
  content: "\e910";
}
.stmicon-laundry-service:before {
  content: "\e917";
}
.stmicon-email:before {
  content: "\10fe7b";
}
.stmicon-maps-and-flags:before {
  content: "\e90c";
}
.stmicon-fax:before {
  content: "\e90d";
}
.stmicon-phone-call:before {
  content: "\e90b";
}
.stmicon-phone:before {
  content: "\10fd1d";
}