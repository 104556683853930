.slider {
  width: 100%;
  height: 100%;
  background-color: black;
}

.slider .loader {
  width: 100%;
  height: 150vh;
}

.slider .angle_right {
  position: absolute;
  right: 5%;
  top: 47%;
}

.slider .angle_left {
  position: absolute;
  left: 5%;
  top: 47%;
}

.slider .ar {
  font-size: 50px !important;
  color: rgb(245, 237, 237);
  cursor: pointer;
}

.slider .ar:hover {
  color: rgb(221, 213, 213);
}

.slider_image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

.slider_text {
  position: absolute;
  height: 90%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  animation: scaleanimation2 4s;
}

.slider_button {
  position: absolute;
  background-color: rgb(214, 171, 0);
  font-family: "Montserrat";
  box-sizing: border-box;
  height: auto;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  width: 280px;
  min-height: 0px;
  min-width: 0px;
  text-align: center;
  line-height: 60px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 17px;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  box-shadow: 3px 4px 20px 0px rgba(156, 127, 22, 0.75);
  -webkit-box-shadow: 3px 4px 22px 0px rgba(156, 127, 22, 0.75);
  -moz-box-shadow: 3px 4px 22px 0px rgba(156, 127, 22, 0.75);
  transition: all 0.4s ease;
  top: 70%;
  right: 50%;
  transform: translateX(50%);
}

.slider_button:hover {
  background-color: #0693e3;
  box-shadow: 3px 4px 32px 0px rgba(83, 78, 130, 0.75);
  -webkit-box-shadow: 3px 4px 32px 0px rgba(83, 78, 130, 0.75);
  -moz-box-shadow: 3px 4px 32px 0px rgba(83, 78, 130, 0.75);
}

.slider_text h3 {
  color: white !important;
  font-size: 2.7em;
  font-weight: 600;
}

.slider_text h5 {
  color: white !important;
  font-size: 1.3em;
  font-weight: 400;
  width: 50%;
  text-align: center;
  opacity: 80%;
  margin-top: 2%;
}

.slider_image img {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  transform: scale(1, 1.14);
  animation: scaleanimation 4s;
}

.slider_image img::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size: 100%;
  z-index: -1;
}

.slider_image::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-shadow: inset 1px 300px 0px 46px rgba(70, 57, 57, 0.57),
    inset 1px 0px 57px 46px rgba(36, 31, 31, 0.747);
  -moz-box-shadow: inset 1px 200px 0px 46px rgba(170, 153, 153, 0.57),
    inset 1px 100px 57px 46px rgba(36, 31, 31, 0.747);
  box-shadow: inset 1px 100px 30px 0px rgba(0, 0, 0, 0.17);
  background: linear-gradient(
    0deg,
    transparent 0%,
    transparent 0%,
    rgba(8, 8, 8, 0.938) 85%,
    rgb(0, 0, 0) 100%
  );
  opacity: 84%;
}

@keyframes scaleanimation {
  from {
    transform: scale(2.3);
  }
}

@keyframes scaleanimation2 {
  from {
    transform: scale(1.3);
  }
}

@media only screen and (max-width:850px) {
  .slider_image img{
    height: 100%;
    width: auto !important;
    aspect-ratio: .6/.7;
  }

  .slider {
    translate: 0 0;
  }
}