@import "react-datepicker/dist/react-datepicker";
.conferenceid {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conferenceid .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.conferenceid > .content {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  gap: 2.7%;
  margin-bottom: 7%;
  min-height: 45vh !important;
}

.conferenceid .content .conference_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.conferenceid .content .conference_container h2 {
  color: #3a3534;
  font-size: 3.2em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 0;
  margin-bottom: 4%;
}

.conferenceid .content .conference_container img {
  width: 80%;
  aspect-ratio: 1/0.5;
}

.conferenceid .content .conference_container .details {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.conferenceid .content .conference_container .details .elements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10%;
  width: 100%;
}

.conferenceid .content .conference_container .details h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-transform: uppercase;
}

.conferenceid .content .conference_container .details .elements .element {
  width: 44%;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5%;
  border-bottom: 1.5px solid rgba(58, 53, 53, 0.166);
  margin-top: 2%;
  display: flex;
  color: rgba(58, 53, 53, 0.966);
  justify-content: space-between;
  font-size: 1.1em;
}

.conferenceid .content .conference_container .details h4 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 1em;
  font-weight: 400;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.conferenceid .content .buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
}

.conferenceid .content .conference_container button,
.conferenceid .content .buttons button {
  /* background-color: var(--orange);
  border-radius: 2%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  color: white;
  padding: 1% 2.5%;
  transition: all 0.5s ease;
  cursor: pointer;
  width: 30%;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 50px;
  border: 1px solid transparent; 
  height: 45px;*/
  display: flex;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  min-width: 200px;
  height: 45px;
  background-color: var(--orange);
  padding: 2%;
  border-radius: 3px;
  outline: none;
  border: none;
  color: white;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.4s ease;
  align-self: center;
  border: 1px solid transparent;
}

.conferenceid .content .buttons button {
  margin-top: 0px;
}

.conferenceid .content .conference_container button:hover,
.conferenceid .content .buttons button:hover {
  background-color: white;
  border: 1px solid var(--orange);
  color: var(--orange);
}

.listmenus {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.listmenus > .title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 1em;
  font-weight: 400;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.listmenus > .title h2 {
  font-size: 34px;
  line-height: 120%;
  letter-spacing: 0.005em;
  margin-bottom: 0;
}

.listmenus .content {
  width: 100%;
  margin: 2% 0;
}

.listmenus .all_menus {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.listmenus .menu_item {
  width: 31%;
  margin: 1%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #333333;
  font-weight: 500;
  min-width: 300px;
  border: 1px solid #3a3534;
  position: relative;
}

.listmenus .menu_item img {
  width: 100%;
  aspect-ratio: 1/0.7;
}

.listmenus .menu_item .menu_bot {
  display: flex;
  flex-direction: column;
  /* height: 20%;
  min-height: 30px;
  min-width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #333333;
  color: white; */
}

.listmenus .menu_item .menu_bot > * {
  display: flex;
  margin: 2%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1%;
  border-bottom: 1px solid #333333;
}

.listmenus .menu_item .menu_bot > *:first-child {
  padding-top: 1%;
}

.listmenus .menu_item .menu_bot > *:last-child {
  border-bottom: none;
  cursor: pointer;
  color: var(--orange);
}

.listmenus .menu_item .menu_right {
  position: absolute;
  top: 0;
  right: 0;
  aspect-ratio: 1/0.7;
  width: 100%;
  background-color: rgb(37, 121, 37);
  opacity: 0;
}

.listmenus .menu_item .menu_right::after {
  content: "SELECT";
  font-weight: 24px;
  position: relative;
  color: white;
  top: 50%;
  left: 40%;
  translate: -50% -50%;
}

.listmenus .menu_item .menu_right:hover {
  opacity: 0.6;
  cursor: pointer;
}

.menumodal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0;
  z-index: 999;
}

.errormodal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0;
  z-index: 999;
}

.errormodal > .wrapperblur {
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errormodal .wrapperblur .content {
  background-color: white;
  border-radius: 8px;
  width: 40%;
  min-width: 250px;
  padding: 3% 5%;
  box-shadow: 1px 4px 27px 0px rgba(217, 204, 204, 0.75);
  -webkit-box-shadow: 1px 4px 27px 0px rgba(217, 204, 204, 0.75);
  -moz-box-shadow: 1px 4px 27px 0px rgba(217, 204, 204, 0.75);
}

.errormodal > .wrapperblur .content h3 {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: rgb(163, 80, 80);
  margin-top: 1%;
}

.errormodal > .wrapperblur .content .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2%;
  margin: 5% 0 0 0;
}

.errormodal > .wrapperblur .content .buttons .fullbutton {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  background-color: var(--orange);
  padding: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.errormodal > .wrapperblur .content .buttons .fullbutton.cancel {
  background-color: #76777c;
}

.menumodal > .wrapperblur {
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menumodal > .wrapperblur .content {
  background-color: white;
  border-radius: 8px;
  width: 40%;
  min-height: 25%;
  padding: 3% 5%;
  box-shadow: 1px 4px 27px 0px rgba(217, 204, 204, 0.75);
  -webkit-box-shadow: 1px 4px 27px 0px rgba(217, 204, 204, 0.75);
  -moz-box-shadow: 1px 4px 27px 0px rgba(217, 204, 204, 0.75);
}

.menumodal > .wrapperblur .content h3 {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #333333;
  margin-top: 1%;
}

.menumodal > .wrapperblur .content .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2%;
  margin: 5% 0 0 0;
}

.menumodal > .wrapperblur .content .buttons .fullbutton {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  background-color: var(--orange);
  padding: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.menumodal > .wrapperblur .content .buttons .fullbutton.cancel {
  background-color: #76777c;
}

.menumodal .plats {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
}

.menumodal .plats .plat {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.menumodal .plats .plat img {
  width: 100%;
  aspect-ratio: 1/0.6;
  border-radius: 2px;
}

.menumodal .plats .plat > span {
  font-size: 30px;
  text-transform: uppercase;
  text-decoration: underline;
  margin: 2%;
}

.menumodal .plats .plat .delete_plat {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 120px;
  background-color: rgb(207, 111, 111);
  opacity: 0;
  border-radius: 8px;
}

.menumodal .plats .plat:hover .delete_plat {
  opacity: 0.7;
}

.menumodal .plats .plat:hover .delete_plat::after {
  content: "DELETE";
  color: white;
  font-size: 1.3em;
  position: relative;
  top: 40%;
  left: 40%;
}

.conferenceid .content > button {
  width: 10%;
  margin-top: 0;
  min-width: 150px;
}

.devisform {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.devisform .container {
  margin: 5% 0;
  width: 85%;
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-bottom: 5%;
}

.devisform .container .left {
  flex: 1;
  padding: 1% 3%;
  background-color: var(--grey);
  height: 100%;
}

.devisform .container .right {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.devisform .container .left h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  min-width: 270px;
}

.devisform .container .left div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.devisform .container .left div i {
  font-size: 2.2em;
  color: var(--orange);
  margin: 2% 2% 2% 0;
}

.devisform .container .left div span {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 1.3em;
  font-weight: 400;
}

.devisform .container > .left > div:last-child span {
  font-size: 1.1em;
}

.devisform .container > .left > div:last-child {
  margin-bottom: 50px;
}

.devisform .right form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%;
  margin-bottom: 75px;
}

.devisform .right form .input {
  display: flex;
  flex-direction: column;
  width: 48%;
  min-width: 300px;
  margin-bottom: 3%;
}
.devisform .right form input.error {
  border-color: rgb(211, 49, 49) !important;
}

.devisform .right form .input.full {
  width: 100%;
}

.devisform .right form .input > span {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1em;
  display: inline-block;
  margin-bottom: 14px;
  font-family: "Montserrat Alternates";
  color: rgba(34, 34, 34, 0.7);
}

.devisform .right form .input input {
  font-family: "Montserrat";
  height: 39px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
  margin-bottom: 4%;
  font-size: 1.3em;
  border-radius: 0;
  padding: 1% 1% 1% 3%;
  color: var(--black);
  transition: all 0.4s ease;
  width: 95%;
}

.devisform .right form .input textarea {
  font-family: "Montserrat";
  min-width: 250px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
  font-size: 1.3em;
  min-width: 250px;
  border-radius: 0;
  color: var(--black);
  padding: 1% 1% 1% 3%;
  transition: all 0.4s ease;
}

.devisform .right form .input .desc {
  font-size: 0.7em;
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-style: italic;
  text-transform: none;
  margin-top: 10px;
}

.devisform .right form .input textarea:focus,
.devisform .right form .input input:focus {
  border: 2px solid var(--orange);
  background-color: white;
}

.devisform .right form .input > .basic_select ul {
  background-color: transparent;
}

.devisform .right form .input > .basic_select li {
  height: 39px;
  padding: 1% 5% 1% 3%;
  width: 91%;
  display: flex;
  align-items: center;
}

.devisform .right form .input > .basic_select li:first-child {
  padding: 1% 5% 1% 3%;
  width: 91%;
  display: flex;
  align-items: center;
}

.devisform .right form .input > .basic_select li:first-child span {
  margin-top: 2%;
}

.devisform .right .bookingdetails {
  margin-bottom: 3%;
}

.devisform .right .bookingdetails h3,
.devisform .right .yourreservation h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  line-height: 36px;
  font-weight: 500;
  font-size: 1em;
  text-transform: uppercase;
}

.devisform .right .bookingdetails .detail {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 2%;
  border-bottom: 1px solid rgb(128, 128, 128, 0.3);
}

.devisform .right .bookingdetails .detail:nth-child(4) {
  border-bottom: none;
}

.devisform .right .bookingdetails .detail span {
  font-size: 1.3em;
  color: rgb(82, 77, 77);
}

.devisform .right .yourreservation {
  display: flex;
  flex-direction: column;
}

.devisform .right .yourreservation .head {
  display: flex;
  flex-direction: row;
  background-color: rgb(238, 238, 238);
  color: rgb(61, 59, 59);
  font-weight: 600;
  font-size: 1em;
  padding: 3% 2%;
}

.devisform .right .yourreservation .head .rm,
.devisform .right .yourreservation .element .rm {
  width: 50%;
}

.devisform .right .yourreservation .head .surface,
.devisform .right .yourreservation .element .surface {
  width: 30%;
}

.devisform .right .yourreservation .element {
  padding: 3% 2%;
  display: flex;
  flex-direction: row;
  color: rgb(61, 59, 59);
  font-weight: 600;
  font-size: 1em;
  border-bottom: 1px solid rgb(143, 136, 136, 0.4);
}

.devisform .right .yourreservation .total {
  display: flex;
  justify-content: space-between;
  padding: 3% 2%;
  color: #3a3534;
  font-weight: 700;
  font-size: 1.3em;
}

.devisform .right > p {
  color: rgb(68, 62, 61, 0.8);
  line-height: 1.5em;
}

.devisform .right > button {
  background-color: var(--orange);
  padding: 2%;
  border-radius: 3px;
  outline: none;
  border: none;
  color: white;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.4s ease;
  align-self: center;
}

.devisform .right button:hover {
  background-color: #3a3534;
}

.devisform .paymentmethod {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.devisform .paymentmethod span {
  display: block;
  padding: 0px 10px;
  margin: 0 30px;
  border: 2px solid var(--orange);
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.4em;
  color: var(--black);
  width: 300px;
  transition: all 0.4s ease;
}

.devisform .paymentmethod span:hover,
.devisform .paymentmethod span.active {
  background-color: var(--orange);
  color: white;
}

.devisform h2 {
  margin-top: 50px;
  color: #3a3534;
  font-weight: 600;
}

.conferenceid h5 {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: rgb(72, 211, 72);
  margin-top: 0;
}

.inl_input {
  display: flex;
  flex-direction: row;
  width: 55%;
  align-items: center;
}

.inl_input > span {
  width: 250px;
}

.inl_input input {
  font-family: "Montserrat";
  height: 39px;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--orange);
  font-size: 1.3em;
  border-radius: 0;
  padding: 1% 1% 1% 3%;
  color: var(--black);
  transition: all 0.4s ease;
  width: 150px;
  background-color: var(--grey);
  margin-right: 2%;
}

@media only screen and (max-width:850px) {
  .conferenceid > .content {
    margin-top: 20vh;
  }
  .conferenceid .content .conference_container .details .elements .element {
    flex-direction: column;
    width: 100%;
  }
  .listmenus {
    text-align: center;
  }
  .listmenus .all_menus {
    flex-direction: column;
    align-items: center;
  }
  .devisform .container {
    flex-direction: column;
    margin-bottom: 5vh;
  }
  .devisform .paymentmethod {
    flex-direction: column;
  }
  .devisform .paymentmethod span:first-child{
    margin-bottom: 5%;
  }
  .devisform .right .bookingdetails .detail {
    flex-direction: column;
  }

  .inl_input {
    width: 100%;
  }

  .conferenceid .content .buttons {
    flex-direction: column;
  }

  .conferenceid .content .buttons button {
    width: 250px;
  }

  .conferenceid .content .buttons button:first-child{
    margin-bottom: 3%;
  }
}