.home .slider_show {
  width: 100%;
  height: 100vh;
  background-color: black;
}

.home {
  width: 100%;
}

.home .checkoutandcontent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  margin: auto;
}

.home .services {
  margin-top: 12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 12%  auto auto auto;
}

.home .services > i {
  font-size: 1.7em;
  color: var(--black);
}

.home .services > h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 3.2em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 2%;
  margin-bottom: 5%;
}

.home .rooms {
  height: 110vh;
  margin-top: 7%;
  background-color: #3a3138 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .rooms i {
  margin-top: 4.7%;
  font-size: 1.9em;
  color: var(--white);
}

.home .rooms h3 {
  font-family: "Montserrat Alternates";
  color: var(--white);
  font-size: 3.13em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 1.8%;
  margin-bottom: 0.8%;
}

.home .rooms ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 39%;
  align-items: center;
}

.home .rooms ul li {
  font-family: "Montserrat Alternates";
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  font-size: 0.88em;
  color: var(--white);
  margin: 0 11.2% 0 0;
  cursor: pointer;
}
.home .rooms ul li::after {
  content: "";
  margin-top: 8px;
  width: 100%;
  height: 1px;
  display: block;
  background-color: var(--orange);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.home .rooms ul li.selected::after {
  opacity: 1;
}

.home .rooms .rooms_types {
  height: 53%;
  margin-top: 3%;
  width: 85%;
  display: flex;
  flex-direction: row;
  gap: 2.5%;
}

.whyus {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6%;
  max-width: 1400px;
  margin: auto;
}

.whyus > i {
  margin-top: 4.7%;
  font-size: 1.9em;
  color: var(--black);
}

.whyus > h3 {
  color: var(--black);
  font-family: "Montserrat Alternates";
  font-size: 3.13em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 1.8%;
  margin-bottom: 0.8%;
}

.whyus > h4 {
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  width: 40%;
  text-align: center;
}

.whyus .icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

.whyus .icons .icon {
  margin: 2%;
  display: flex;
  flex-direction: column;
}

.whyus .icons .ic {
  position: relative;
  margin-bottom: 30px;
}

.whyus .icons .ic i {
  display: block;
  min-width: 160px;
  min-height: 160px;
  line-height: 160px;
  border-radius: 50%;
  transition: 0.3s ease;
  font-size: 4.5em;
  text-align: center;
}

.whyus .icons .ic:hover i::after {
  transform: scale(1.15);
}

.whyus .icons .ic:hover i {
  color: var(--orange);
}

.whyus .icons .ic i::after {
  width: 160px !important;
  height: 160px !important;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 3px 3px 17px rgba(51, 51, 51, 0.15);
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.06, 0.43, 0.22, 1);
  left: 18%;
}

.whyus .icons .icon span {
  display: block;
  width: 250px;
  height: 10px;
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-align: center;
}

.gallerie {
  background-color: #3a3138 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallerie i {
  margin-top: 4.7%;
  font-size: 1.9em;
  color: var(--orange);
}

.gallerie h3 {
  font-family: "Montserrat Alternates";
  color: var(--white);
  font-size: 3.13em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 1.8%;
  margin-bottom: 0.8%;
}

.avis {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.avis > i {
  margin-top: 5.8%;
  font-size: 1.7em;
  color: var(--black);
}

.avis h3 {
  font-family: "Montserrat Alternates";
  color: var(--black);
  font-size: 3.13em;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1px;
  margin: 1.8%;
  margin-bottom: 0.8%;
}

.avis .feedbacks {
  margin: 5% 0;
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: center;
  gap: 4.2%;
  flex-wrap: wrap;
}

.avis > button {
  margin-top: 5%;
  color: #fff;
  background-color: #2a2a2a;
  border: 1px solid #2a2a2a;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9em;
  padding: 1.1% 1.9%;
  font-family: "Montserrat";
  line-height: 5px;
  white-space: nowrap;
  flex: 1.1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7%;
}

.avis > button > i {
  margin: 0 10% 0 0%;
  font-size: 16px;
}

.location {
  display: flex;
  flex-direction: row;
  min-height: 800px;
  height: 120vh;
  background-color: var(--white);
  z-index: 1;
}

.location > .left {
  display: flex;
  flex-direction: column;
  width: 34%;
  align-items: center;
  height: 100%;
}
.location > .left > h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 3em;
  line-height: 45px;
  font-weight: 400;
  width: 61%;
  margin-top: 32%;
}

.location > .left > h3 span {
  font-family: "Great Vibes";
  font-size: 73px;
}

.location .items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
}

.location > .right {
  width: 66%;
  min-width: 500px;
  height: 100%;
}

.reservez {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  position: relative;
  overflow: hidden;
  transform: scale(1, 1.1);
}

.reservez > i {
  margin-top: 7%;
  font-size: 1.7em;
  color: var(--white);
}

.reservez h3 {
  font-family: "Montserrat Alternates";
  color: var(--white);
  font-size: 2.5em;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 54px;
  margin-top: 2%;
  margin-bottom: 0.8%;
}

.reservez .outlined_button {
  margin-top: 2.5%;
  border: 1px solid var(--orange);
  padding: 16px 30px;
  font-weight: bold;
  border-radius: 4px;
  border-width: 1px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  color: var(--white);
  font-size: 0.885em;
  cursor: pointer;
}

.reservez .outlined_button:hover {
  background-color: var(--orange);
}

.reservez > * {
  z-index: 1;
}
.reservez img {
  height: 60vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform: scale(1, 2.2) translateY(1.3%);
  opacity: 0.8;
  z-index: 0;
}

@media only screen and (max-width: 850px) {
  .home .services {
    margin-top: 300px !important;
  }

  .home .datepicker {
    top: 80px !important;
    z-index: 111 !important;
  }

  .home .services * {
    text-align: center;
  }

  .home .rooms {
    height: auto !important;
    align-items: center;
  }

  .home .rooms * {
    text-align: center;
  }
  .home .rooms ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .home .rooms ul li {
    margin: 0;
  }

  .home .rooms_types {
    flex-direction: column !important;
    align-items: center;
    height: auto;
  }

  .whyus > * {
    text-align: center;
  }

  .whyus .icons .icon {
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
  }

  .whyus .icons .ic i::after {
    translate: -18% 0;
  }

  .home .location {
    height: auto;
    flex-direction: column;
    margin-top: 0;
  }

  .home .location .left {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home .location .left h3 {
    margin: 0;
  }

  .home .location .left .items {
    margin: 10px 0;
  }

  .home .location .right {
    height: 350px;
    width: 100%;
  }

  .home .location {
    flex-direction: column;
  }

  .home .reservez {
    text-align: center;
    height: 40vh;
  }

  .whyus .icons .icon span {
    max-width: 100px;
  }

  .whyus .icons .icon {
    margin-bottom: 30px;
  }
}
