.basic_select {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.basic_select > ul {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: var(--grey);
}

.basic_select > ul > li:first-child > span {
  font-size: 1.5;
  font-weight: 800;
  transform: rotate(90deg);
  transition: all 0.3s ease;
  margin: 0;
}

.basic_select > ul > li {
  width: 80%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10%;
  border-left: 2px solid var(--orange);
  border-right: 2px solid var(--orange);
  font-size: 1em;
  font-family: "Montserrat";
  white-space: nowrap;
  color: var(--black);
}

.basic_select > ul > li:hover {
  background-color: var(--grey);
}

.basic_select > ul > li:first-child {
  background-color: var(--grey);
  justify-content: space-between;
  border: 2px solid transparent;
  border-bottom: none !important;
  transition: all 0.4s ease;
  position: relative;
  z-index: 0 !important;
}

.basic_select > ul > li:nth-child(2) {
  box-shadow: 4px -3px 12px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px -3px 12px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px -3px 12px 0px rgba(0, 0, 0, 0.06);
}

.basic_select > ul > li:last-child {
  border-bottom: 2px solid var(--orange);
}

.basic_select > ul > li:last-child.li-closed {
  border-bottom: none;
}

li.li-open {
  border: 2px solid var(--orange) !important;
  border-bottom: none !important;
  background-color: var(--white) !important;
}

li.li-open,
li.li-open-s {
  box-shadow: 4px 9px 12px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 9px 12px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 9px 12px 0px rgba(0, 0, 0, 0.06);
}


@media only screen and (max-width: 850px) {
  .basic_select {
    min-height: 40px;
    max-height: 50px;
    margin: 2%;
    position: relative;
  }
}