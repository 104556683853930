.room {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 380px;
  max-height: 420px;
  width: 370px;
  transition: all 0.4s ease;
  box-shadow: -1px 14px 22px 0px rgba(247, 247, 247, 0.15);
  -webkit-box-shadow: -1px 14px 22px 0px rgba(247, 247, 247, 0.15);
  -moz-box-shadow: -1px 14px 22px 0px rgba(247, 247, 247, 0.15);
  margin-bottom: 27px;
  padding-bottom: 10px;
  cursor: pointer;
}

.room:hover {
  transform: translateY(-5%);
}

.room .img {
  height: 66%;
  overflow: hidden;
  min-height: 100px;
}

.room .img img {
  height: 100%;
  width: 100%;
}

.room .description {
  height: 42%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.room .description .price {
  position: relative;
  color: #fff;
  font-weight: normal;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 18px;
  background-color: #ffac41 !important;
  line-height: 30px;
  width: 150px;
  top: -40%;
  text-align: center;
  font-family: "Montserrat Alternates" !important;
}

.room .description h4 {
  font-family: "Montserrat Alternates";
  color: var(--black);
  font-size: 1.8em;
  font-weight: 400;
  line-height: 36px;
  position: relative;
  padding: 0;
  margin: 0;
  top: -30%;
  transition: all .4s ease;
}

.room .description h4:hover {
  color: var(--orange);
}

.room .icons {
  width: 80%;
  height: 45%;
  position: relative;
  border-top: 1px solid var(--grey);
  top: -15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
}

.room .icons .icon {
  margin: 1% 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.room .icons .icon i {
  color: var(--orange);
  font-size: 17px;
}

.room .icons .icon span {
    font-size: 14px;
    color: var(--black);
}

@media only screen and (max-width: 850px){
  .room {
    height: 300px;
    width: 100%;
  }
}