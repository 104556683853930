.paymentform {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentform .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.full_loading {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentform .container {
  margin: 5% 0;
  width: 85%;
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-bottom: 5%;
}

.paymentform .container .left {
  flex: 1;
  padding: 1% 3%;
  background-color: var(--grey);
  height: 100%;
}

.paymentform .container .right {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.paymentform .container .left h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  min-width: 270px;
}

.paymentform .container .left div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paymentform .container .left div i {
  font-size: 2.2em;
  color: var(--orange);
  margin: 2% 2% 2% 0;
}

.paymentform .container .left div span {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  font-size: 1.3em;
  font-weight: 400;
}

.paymentform .container > .left > div:last-child span {
  font-size: 1.1em;
}

.paymentform .container > .left > div:last-child {
  margin-bottom: 50px;
}

.paymentform .right form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%;
  margin-bottom: 75px;
}

.paymentform .right form .input {
  display: flex;
  flex-direction: column;
  width: 48%;
  min-width: 300px;
  margin-bottom: 3%;
}
.paymentform .right form input.error {
  border-color: rgb(211, 49, 49) !important;
}

.paymentform .right form .input.full {
  width: 100%;
}

.paymentform .right form .input span {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1em;
  display: inline-block;
  margin-bottom: 14px;
  font-family: "Montserrat Alternates";
  color: rgba(34, 34, 34, 0.7);
}

.paymentform .right form .input input {
  font-family: "Montserrat";
  height: 39px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
  margin-bottom: 4%;
  font-size: 1.3em;
  border-radius: 0;
  padding: 1% 1% 1% 3%;
  color: var(--black);
  transition: all 0.4s ease;
}

.paymentform .right form .input textarea {
  font-family: "Montserrat";
  min-width: 250px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
  font-size: 1.3em;
  min-width: 250px;
  border-radius: 0;
  color: var(--black);
  padding: 1% 1% 1% 3%;
  transition: all 0.4s ease;
}

.paymentform .right form .input .desc {
  font-size: 0.7em;
  font-family: "Montserrat";
  color: rgba(34, 34, 34, 0.7);
  font-style: italic;
  text-transform: none;
  margin-top: 10px;
}

.paymentform .right form .input textarea:focus,
.paymentform .right form .input input:focus {
  border: 2px solid var(--orange);
  background-color: white;
}

.paymentform .right form .input > .basic_select ul {
  background-color: transparent;
}

.paymentform .right form .input > .basic_select li {
  height: 39px;
  padding: 1% 5% 1% 3%;
  width: 91%;
  display: flex;
  align-items: center;
}

.paymentform .right form .input > .basic_select li:first-child {
  padding: 1% 5% 1% 3%;
  width: 91%;
  display: flex;
  align-items: center;
}

.paymentform .right form .input > .basic_select li:first-child span {
  margin-top: 2%;
}

.paymentform .right .bookingdetails {
  margin-bottom: 3%;
}

.paymentform .right .bookingdetails h3,
.paymentform .right .yourreservation h3 {
  font-family: "Montserrat Alternates";
  color: #3a3534;
  line-height: 36px;
  font-weight: 500;
  font-size: 1em;
  text-transform: uppercase;
}

.paymentform .right .bookingdetails .detail {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 2%;
  border-bottom: 1px solid rgb(128, 128, 128, 0.3);
}

.paymentform .right .bookingdetails .detail:nth-child(4) {
  border-bottom: none;
}

.paymentform .right .bookingdetails .detail span {
  font-size: 1.3em;
  color: rgb(82, 77, 77);
}

.paymentform .right .yourreservation {
  display: flex;
  flex-direction: column;
}

.paymentform .right .yourreservation .head {
  display: flex;
  flex-direction: row;
  background-color: rgb(238, 238, 238);
  color: rgb(61, 59, 59);
  font-weight: 600;
  font-size: 1em;
  padding: 3% 2%;
}

.paymentform .right .yourreservation .element {
  padding: 3% 2%;
  display: flex;
  flex-direction: row;
  color: rgb(61, 59, 59);
  font-weight: 600;
  font-size: 1em;
  border-bottom: 1px solid rgb(143, 136, 136, 0.4);
}

.paymentform .right .yourreservation .head > *,
.paymentform .right .yourreservation .element > * {
  width: 25%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paymentform .right .yourreservation .element > * {
  text-align: center;
}

.paymentform .right .yourreservation .total {
  display: flex;
  justify-content: space-between;
  padding: 3% 2%;
  color: #3a3534;
  font-weight: 700;
  font-size: 1.3em;
}

.paymentform .right > p {
  color: rgb(68, 62, 61, 0.8);
  line-height: 1.5em;
}

.paymentform .right button {
  background-color: var(--orange);
  padding: 2%;
  border-radius: 3px;
  outline: none;
  border: none;
  color: white;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.4s ease;
  align-self: center;
}

.paymentform .paymentmethod img {
  margin-left: 6px;
}

.paymentform .right button:hover {
  background-color: #3a3534;
}

p a {
  color: var(--orange);
}

p a:hover {
  text-decoration: underline;
}

.paymentform .paymentmethod {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.paymentform .paymentmethod span {
  display: block;
  padding: 0px 10px;
  margin: 0 30px;
  border: 2px solid var(--orange);
  min-height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.4em;
  color: var(--black);
  width: 300px;
  transition: all 0.4s ease;
}

.paymentform .paymentmethod span:hover,
.paymentform .paymentmethod span.active {
  background-color: var(--orange);
  color: white;
}

.paymentform h2 {
  margin-top: 50px;
  color: #3a3534;
  font-weight: 600;
}

.paymentform h5 {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: rgb(72, 211, 72);
}

@media only screen and (max-width: 850px) {
  .paymentform .container {
    margin-top: 25vh;
    flex-direction: column;
    margin-bottom: 5vh;
  }
  .paymentform .paymentmethod {
    flex-direction: column;
  }
  .paymentform .paymentmethod span:first-child {
    margin-bottom: 5%;
  }
}

.paymentform button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

.paymentform button img {
  width: 75px;
}