:root {
  --padding-space: 1%;
}

.galleriesliderv2 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  max-width: 1400px;
}

.galleriesliderv2 .angle_right,
.galleriesliderv2 .angle_left {
  position: absolute;
  z-index: 1;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  border-radius: 3px;
  cursor: pointer;
  top: 43.8%;
  opacity: 0;
  font-size: 27px !important;
  color: var(--white);
  cursor: pointer;
  font-weight: 400;
  transition: all 0.3s ease;
}

.galleriesliderv2 .angle_left {
  left: calc(10vw);
}

.galleriesliderv2 .angle_right {
  right: calc(10vw);
}

.galleriesliderv2:hover .angle_right,
.galleriesliderv2:hover .angle_left {
  opacity: 1 !important;
}

.galleriesliderv2 .angle_left:hover,
.galleriesliderv2 .angle_right:hover {
  background-color: var(--orange);
}

.galleriesliderv2 .imgs {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1% 0 2.5% 0;
  cursor: pointer;
}

.galleriesliderv2 .imgs > .img {
  min-width: 85.7vw !important;
  max-width: 85.7vw !important;
  padding: var(--padding-space);
  transition: all 0.8s ease;
}

.galleriesliderv2 .imgs > .img > img {
  width: 100%;
  aspect-ratio: 16/9;
}

.galleriesliderv2 h5 {
    font-family: "Montserrat Alternates" !important;
    line-height: 14px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 1.3em;
    font-weight: 400;
    margin: 0.3%;
    margin-bottom: 3%;
  }
