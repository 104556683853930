.checkrooms {
  width: 100vw;
  background-color: var(--white) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkrooms .header {
  position: relative;
  background-color: rgba(1, 1, 1, 0.88);
  width: 88%;
  padding: 2.5% 6%;
  margin: 0;
  padding-bottom: 0.9%;
}

.checkrooms .content {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 7%;
  min-height: 80vh;
}

.checkrooms .content .left {
  flex: 2.2;
}
.checkrooms .content .left .rms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3%;
  margin: 2% 0;
}

.checkrooms .room {
  box-shadow: 0 30px 50px rgba(153, 153, 153, 0.15) !important;
  -webkit-box-shadow: 0 30px 50px rgba(153, 153, 153, 0.15) !important;
}

.checkrooms .room .description .price {
  top: -40px !important;
}

.checkrooms .room .description h4 {
  top: -20px
}

.checkrooms .content .right {
  flex: 1;
}

.checkrooms .loader {
  height: 30vh;
  translate: 0 30%;
}

@media only screen and (max-width: 850px){
  .checkrooms .content{
    margin-top: 20vh;
    width: 85%;
  }
  .checkrooms .content .right {
    display: none;
  }

  .checkrooms .content .rms {
    width: 100%;
  }
}